<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo1" style="display: flex; justify-content: center">
                <img src="../../public/images/logo3.png" alt="logo" style="width: 30%" />
              </div>
              <h4>Sign In</h4>
              <form class="pt-3" @submit.prevent="login">
                <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="msg != ''">
                  <strong>{{ msg }}</strong>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-group">
                  <input type="email" name="email" class="form-control form-control-lg" id="exampleInputEmail1"
                    v-model="email" placeholder="Email" style="color: #242323" />
                </div>
                <div class="form-group">
                  <input type="password" name="password" class="form-control form-control-lg" id="exampleInputPassword1"
                    v-model="password" placeholder="Password" style="color: #242323" />
                </div>
                <div class="mt-3">
                  <button type="submit" style="background-color: #1e1e2f; border: none"
                    class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-white">
                    SIGN IN
                    <span class="circle-loader" style="display: none" id="loader"></span>
                  </button>
                </div>
                <div class="my-2 d-flex justify-content-between align-items-center">
                  <router-link to="/forgot-password" class="auth-link text-black">Forgot password?</router-link>
                </div>
                <div class="text-center mt-4 font-weight-light">
                  Don't have an account?
                  <router-link to="/register" class="text-black font-weight-bold">Create</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>

<script>
import baseUrl from "@/shared/baseUrl";
export default {
  name: "HelloWorld",
  data() {
    return {
      email: "",
      password: "",
      msg: "",
    };
  },
  methods: {
    login() {
      $("#loader").show();
      const user = {
        email: this.email,
        password: this.password,
      };
      if (user.email == "" || user.password == "") {
        this.msg = "Please fill all the fields";
        $("#loader").hide();
      } else {
        baseUrl
          .post("/login", user)
          .then((response) => {
            $("#loader").hide();
            console.log('The login response data>>>',response.data);
            if (response.data.status == "03") {
              this.msg = response.data.message;
            } else {
              console.log(response.data.data);
              localStorage.setItem("user", response.data.data.companyName);
              localStorage.setItem("token", response.data.token);
              if(response.data.data.role){
                const userRole = response.data.data.role.toUpperCase();
                localStorage.setItem("userRole", response.data.data.role.toUpperCase());
              }else{
                localStorage.setItem("userRole", "USER");
              }
              const mobileNumber =response.data.data.mobileNumber;
              this.$store.commit("setDialingPhone", mobileNumber);
              localStorage.setItem("dialerMobile", mobileNumber);
              this.$router.push("/app-list");
            }
          })
          .catch((error) => {
            $("#loader").hide();
            this.msg = error.message;
          });
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Open Sans", sans-serif !important;
}

#loader {
  margin-left: 10px;
}
</style>
